import Axios from 'axios';


export default async function PostUpdateConteudo(BaseUrl, dados) {
    console.log('dadosUpdate: ', {formulario: dados})
    const resultUpdate = await Axios.post(`${BaseUrl}/updateConteudo`,{
        formulario: dados
    })

    return resultUpdate.data
}
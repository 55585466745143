export default function FiltrarDisciplinas(conteudos, disciplinas) {
    let novoConteudo = []

    conteudos.filter((curso, index) => {
        const resultado = disciplinas.filter(disciplina => disciplina.Codigo === curso?.disciplina_id)[0]
        novoConteudo.push(curso)

        if (resultado?.Disciplina !== undefined) {
            novoConteudo[index]['Disciplina'] = resultado?.Disciplina
        } else {
            novoConteudo[index]['Disciplina'] = 'Polivalente'
        }
    })

    return novoConteudo
}
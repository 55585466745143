import { useState } from 'react';
import FormularioAula from './FormularioAula';
import FormularioFaltas from './FormularioFaltas';
import './ModalEditarAula.css'
import Modal from 'react-modal';

const conteudosProps = {
    id: 0,
    data: "",
    created_at: "",
    updated_at: "",
    curso_id: "",
    Curso: "",
    disciplina_id: "",
    Disciplina: "",
    turma_id: "",
    descricao: "",
    atividade_aula: "",
    atividade_casa: "",
    aulas: 0,
    etapa: 0,
    bloqueado: 0,
    codProfessor: "0"
}

export default function ModalEditarAula({ modalIsOpen = false,
    dadosAula = conteudosProps,
    valorAula = 0,
    valorConteudo = '',
    valorAtividadeAula = '',
    valorAtividadeCasa = '',
    alunosPermitidos = [],
    minData = '',
    onCloseModal = () => { },
    onChangeConteudo = () => { },
    onChangeAulas = () => { },
    onChangeAtividadeAula = () => { },
    onChangeAtividadeCasa = () => { },
    setFormularioAula = () => { },
    setFormularioFaltas = () => { },
    onSubmit = () => { }
}) {
    const [page, setPage] = useState(0)

    const onNextPage = () => {
        const formAula = {
            turma: dadosAula.turma_id,
            disciplina: dadosAula.disciplina_id,
            curso: dadosAula.curso_id,
            data: dadosAula.data,
            aulas: valorAula,
            conteudo: valorConteudo,
            atividade_aula: valorAtividadeAula,
            atividade_casa: valorAtividadeCasa,
        }
        setFormularioAula(formAula)
        setPage(page + 1)
    }
    const onCloseModalPage = () => {
        setPage(0)
        onCloseModal()
    }

    const onPreviousPage = () => {
        setPage(page - 1)
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={onCloseModalPage}
            contentLabel="Example Modal"
            overlayClassName="modal-overlay"
            className="modal-content-editar-aula"
        >
            {
                page === 0 ?
                    <FormularioAula
                        dadosAula={dadosAula}
                        valorAula={valorAula}
                        valorConteudo={valorConteudo}
                        valorAtividadeAula={valorAtividadeAula}
                        valorAtividadeCasa={valorAtividadeCasa}
                        onNextPage={onNextPage}
                        onCloseModal={onCloseModal}
                        onChangeConteudo={onChangeConteudo}
                        onChangeAtividadeAula={onChangeAtividadeAula}
                        onChangeAtividadeCasa={onChangeAtividadeCasa}
                        onChangeAulas={onChangeAulas}
                        minData={minData}
                    />
                    :
                    <FormularioFaltas
                        onPreviousPage={onPreviousPage}
                        alunosPermitidos={alunosPermitidos}
                        valorAulas={valorAula}
                        onChangeAulas={onChangeAulas}
                        setFormularioFaltas={setFormularioFaltas}
                        onSubmit={onSubmit}
                        disciplina_id={dadosAula.disciplina_id}
                        data={dadosAula.data?.slice(0,10)}
                    />
            }

        </Modal>
    )
}

export default function GerarCheckBoxPorAula(aulas, val, matriculaField, register) {
    let aulasNumero = parseInt(aulas)
    let valor = 0
    let checkArray = []

    while (valor < aulasNumero) {
        valor = valor + 1
        checkArray.push(<input type="checkbox" value={`check${valor}`} name={val[matriculaField]} ref={register} />)
    }

    return checkArray.map((item) => {
        return item
    })
}
import Axios from 'axios'

export default async function GetAlunosPermitidosFaltas(BaseUrl, curso, turma, disciplina, codProfessor, data){
    const alunosPermitidos = await Axios.post(`${BaseUrl}/alunosPermitidosFaltas`,
    {
        curso: curso,
        turma: turma,
        disciplina: disciplina,
        codProfessor: codProfessor,
        data: data
    })
    
    return alunosPermitidos.data
}
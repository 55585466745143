import './ConsultarAulas.css'
import { useState, useEffect } from 'react';
import { CircularProgress, Backdrop } from '@mui/material'

import Header from '../../../components/header';
import DiarioTabela from '../../../components/DiarioTabela/DiarioTabela';
import ModalEditarAula from '../../../components/ModalEditarAula/ModalEditarAula';
import DiarioPainelPesquisa from '../../../components/DiarioPainelPesquisa/DiarioPainelPesquisa';

import { ApiFilter } from '../../../Functions/FilterApiDomain';
import FiltrarDadosDiario from '../../../Functions/FiltrarDadosDiario';

import ConsultarAulasProf from '../../../services/ConsultarAulasProf';
import GetCursos from '../../../services/GetCursos'
import GetDisciplina from '../../../services/GetDisciplinas'
import GetTurmas from '../../../services/GetTurmas'
import GetConteudos from '../../../services/GetConteudos'
import FiltrarConteudosPorDisciplina from './utils/FiltrarConteudos';
import GetAlunosPermitidosFaltas from '../../../services/GetAlunosPermitidos';
import GetFaltasAlunos from '../../../services/GetFaltas';
import PostUpdateFaltas from '../../../services/PostUpdateFaltas';
import PostUpdateConteudo from '../../../services/PostUpdateConteudo';
import ValidarBloqueioDiario from '../../../Functions/ValidarBloqueioDiario';
import CalcularDataBloqueioDiario from '../../../Functions/CalcularDataBloqueioDiario';
import ConfigService from '../../../services/config';

const conteudosProps = {
    id: 0,
    data: "",
    created_at: "",
    updated_at: "",
    curso_id: "",
    disciplina_id: "",
    turma_id: "",
    descricao: "",
    atividade_aula: "",
    atividade_casa: "",
    aulas: 0,
    etapa: 0,
    bloqueado: 0,
    codProfessor: "0"
}

export default function ConsultarAulas() {
    const [conteudos, setConteudos] = useState([conteudosProps])
    const [cursos, setCursos] = useState([])
    const [disciplinas, setDisciplinas] = useState([])
    const [turmas, setTurmas] = useState([])

    const [valorCurso, setValorCurso] = useState('')
    const [valorDisciplina, setValorDisciplina] = useState('')
    const [valorTurma, setValorTurma] = useState('')
    const [valorDataInicial, setValorDataInicial] = useState('')
    const [valorDataFinal, setValorDataFinal] = useState('')

    const [filtroTurmas, setFiltroTurmas] = useState([])
    const [filtroConteudos, setFiltroConteudos] = useState([])
    const [userCursos, setUserCursos] = useState(sessionStorage.getItem('c'))
    const [conteudosProf, setConteudosProf] = useState([conteudosProps])
    const [searchStatusBarIcon, setSearchStatusBarIcon] = useState(true)
    const [aula, setAula] = useState(conteudosProps)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isSending, setIsSending] = useState(false)
    const [valorConteudo, setValorConteudo] = useState('')
    const [valorAula, setValorAula] = useState(0)
    const [valorAtividadeAula, setValorAtividadeAula] = useState('')
    const [valorAtividadeCasa, setValorAtividadeCasa] = useState('')
    const [formularioFaltas, setFormularioFaltas] = useState({})
    const [minData, setMinData] = useState('')
    const [configInfo, setConfigInfo] = useState({
        id: 0, Ano: 2024, Status: "ativo", Nota_Conceito: 0, ExcluirDados: 0, decimais_nota: 0, bloqueio_notas: 0, bloqueio_diario: 0, bloqueio_diario_dias: 0
    })
    const [alunosPermitidos, setAlunosPermitidos] = useState([])
    const [formularioAula, setFormularioAula] = useState({
        aulas: 0,
        atividade_aula: '',
        atividade_casa: '',
        conteudo: '',
        turma: '',
        disciplina: '',
        curso: '',
        data: ''
    })

    const codProfessor = sessionStorage.getItem('professores')
    const escola = localStorage.getItem('escola')
    const sub = localStorage.getItem('target')
    const baseUrl = ApiFilter(sub)

    async function GetConfigInfo(baseUrl) {
        const config = await ConfigService(baseUrl)
        setConfigInfo(config[0])
    }

    const titlesHeader = [
        'Curso',
        'Turma',
        'Disciplina',
        'Data',
        'Aula'
    ]

    const fieldsBody = [
        'Curso',
        'turma_id',
        'Disciplina',
        'data',
        'aulas'
    ]

    const onCloseModal = () => {
        setModalIsOpen(false)
        setAlunosPermitidos([])
    }

    const onChangeCurso = async (event) => {
        const valorCursoAtual = event.target.value
        setValorCurso(valorCursoAtual)

        const filtroTurma = turmas.filter(item => item.Curso == valorCursoAtual)
        setFiltroTurmas(filtroTurma)

        const filtroDados = await FiltrarDadosDiario(
            conteudos,
            valorCursoAtual,
            valorTurma,
            valorDisciplina,
            valorDataInicial,
            valorDataFinal
        )

        setFiltroConteudos(filtroDados)
        return filtroDados
    }

    const onChangeDisciplina = async (event, conteudosPrev = []) => {
        const valorDisciplinaAtual = event.target.value
        setValorDisciplina(valorDisciplinaAtual)

        const filtroDados = await FiltrarDadosDiario(
            conteudosPrev.length > 0 ? conteudosPrev : conteudos,
            valorCurso,
            valorTurma,
            valorDisciplinaAtual,
            valorDataInicial,
            valorDataFinal
        )
        setFiltroConteudos(filtroDados)
        return filtroDados
    }

    const onChangeTurma = async (event, conteudosPrev = []) => {
        const valorTurmaAtual = event.target.value
        setValorTurma(valorTurmaAtual)

        const filtroDados = await FiltrarDadosDiario(
            conteudosPrev.length > 0 ? conteudosPrev : conteudos,
            valorCurso,
            valorTurmaAtual,
            valorDisciplina,
            valorDataInicial,
            valorDataFinal
        )
        setFiltroConteudos(filtroDados)
        return filtroDados
    }

    const onChangeDataInicio = async (event) => {
        const valorDataInicioAtual = event.target.value
        setValorDataInicial(valorDataInicioAtual)

        const filtroDados = await FiltrarDadosDiario(
            conteudos,
            valorCurso,
            valorTurma,
            valorDisciplina,
            valorDataInicioAtual,
            valorDataFinal
        )
        setFiltroConteudos(filtroDados)
        return filtroDados
    }

    const onChangeDataFinal = async (event) => {
        const valorDataFinalAtual = event.target.value
        setValorDataFinal(valorDataFinalAtual)

        const filtroDados = await FiltrarDadosDiario(
            conteudos,
            valorCurso,
            valorTurma,
            valorDisciplina,
            valorDataInicial,
            valorDataFinalAtual
        )
        setFiltroConteudos(filtroDados)
        return filtroDados
    }

    async function filtrarConteudos(conteudoConsulta, disciplinas) {
        const conteudoFiltro = FiltrarConteudosPorDisciplina(conteudoConsulta, disciplinas)
        setConteudos(conteudoFiltro)
        setFiltroConteudos(conteudoFiltro)
    }

    function cursosUserPermissao() {
        const permitidos = userCursos?.split(';')?.filter(item => item !== '')
        setUserCursos(['001', '002', '003', '004', '005', '006', '007', '008', '009', '010', '011', '012', '013', '014', '015', '016', '017', '018', '019', '020'])
    }

    const onClickAula = (aula) => {
        setModalIsOpen(true)
        setAula(aula)
        setValorConteudo(aula?.descricao)
        setValorAula(aula?.aulas)
        setValorAtividadeAula(aula?.atividade_aula)
        setValorAtividadeCasa(aula?.atividade_casa)
    }

    const onChangeConteudo = (event) => {
        setValorConteudo(event.target.value)
    }

    const onChangeAtividadeAula = (event) => {
        setValorAtividadeAula(event.target.value)
    }

    const onChangeAtividadeCasa = (event) => {
        setValorAtividadeCasa(event.target.value)
    }

    const onChangeAulas = (event) => {
        setValorAula(event.target.value)
    }

    const onGetAlunosPermitidos = async () => {
        const alunosPermitidos = await GetAlunosPermitidosFaltas(baseUrl,
            formularioAula.curso,
            formularioAula.turma,
            formularioAula.disciplina,
            codProfessor,
            formularioAula.data?.slice(0, 10))

        setAlunosPermitidos(alunosPermitidos)
    }

    useEffect(() => {
        GetConfigInfo(baseUrl)
        Promise.allSettled([
            ConsultarAulasProf(baseUrl, escola, codProfessor),
            GetCursos(baseUrl, escola),
            GetDisciplina(baseUrl, escola),
            GetTurmas(baseUrl, escola),
            GetConteudos(baseUrl, escola)
        ])
            .then((response) => {
                setConteudosProf(response[0].value)
                setCursos(response[1].value)
                setDisciplinas(response[2].value)
                setTurmas(response[3].value)
                cursosUserPermissao()
                filtrarConteudos(response[0].value, response[2].value)
            })
        setSearchStatusBarIcon(false)
    }, [])

    useEffect(() => {
        if (configInfo.id !== 0) {
            const bloqueioDiario = ValidarBloqueioDiario(configInfo)

            if (bloqueioDiario.bloqueioDiario === 1) {
                const dataMinima = CalcularDataBloqueioDiario(bloqueioDiario.bloqueioDias, configInfo.Ano)
                if(dataMinima !== undefined) setMinData(dataMinima)
            }
        }
    }, [configInfo])

    useEffect(() => {
        if (formularioAula.turma !== '') {
            onGetAlunosPermitidos()
        }
    }, [formularioAula])

    const onSubmit = async (formularioFaltas) => {
        setIsLoading(true)

        formularioAula.data = formularioAula.data?.slice(0, 10)

        Promise.allSettled([
            PostUpdateConteudo(baseUrl, formularioAula),
            PostUpdateFaltas(baseUrl, escola, formularioFaltas)
        ])
            .then((response) => {
                console.log('response update: ', response)
                window.alert('Dados atualizados com sucesso!')
                window.location.reload()
            })
            .catch((error) => {
                console.log('error update: ', error)
                window.alert('Erro ao atualizar dados!')
                window.location.reload()
            })
    }


    return (
        <div >
            <Header />

            <Backdrop
                style={{ zIndex: 1000 }}
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className="container">
                <section className="section-consultarAulas">
                    <div className="info-bar" id="infoBarDiario">
                        <div className="containerTitleConsultarDiario">
                            <p className="consultarDiarioTitle">Consultar Diário</p>
                            {/* <p className="subtitle-page">Colégio {localStorage.getItem('escola').toUpperCase()}</p> */}
                        </div>
                        <div className="container-informativo">
                            <p className="informativo-title">Observações</p>
                            <p className="informativo-desc">Os campos Atividades de Aula e Atividades de Casa são opcionais, porém se preenchidos, serão disponibilizados para Pais e Alunos.</p>
                        </div>
                    </div>

                    <ModalEditarAula
                        onCloseModal={onCloseModal}
                        modalIsOpen={modalIsOpen}
                        dadosAula={aula}
                        valorAula={valorAula}
                        valorConteudo={valorConteudo}
                        valorAtividadeAula={valorAtividadeAula}
                        valorAtividadeCasa={valorAtividadeCasa}
                        onChangeConteudo={onChangeConteudo}
                        onChangeAtividadeAula={onChangeAtividadeAula}
                        onChangeAtividadeCasa={onChangeAtividadeCasa}
                        onChangeAulas={onChangeAulas}
                        setFormularioAula={setFormularioAula}
                        setFormularioFaltas={setFormularioFaltas}
                        alunosPermitidos={alunosPermitidos}
                        onSubmit={onSubmit}
                        minData={minData}
                    />

                    <div id='container-tabela'>
                        <DiarioPainelPesquisa
                            cursos={cursos}
                            disciplinas={disciplinas}
                            filtroTurmas={filtroTurmas}
                            onChangeCurso={onChangeCurso}
                            onChangeTurma={onChangeTurma}
                            onChangeDisciplina={onChangeDisciplina}
                            onChangeDataInicio={onChangeDataInicio}
                            onChangeDataFinal={onChangeDataFinal}
                            valorCurso={valorCurso}
                            valorTurma={valorTurma}
                            valorDisciplina={valorDisciplina}
                            valorDataInicial={valorDataInicial}
                            valorDataFinal={valorDataFinal}
                            userCursos={userCursos}
                            key={'DiarioPainelPesquisa'}
                        />

                        <DiarioTabela
                            searchBarShow={false}
                            headerTableTitles={titlesHeader}
                            bodyTableFields={fieldsBody}
                            bodyDataRows={filtroConteudos}
                            searchStatusIcon={searchStatusBarIcon}
                            redirectTo="/Diario/Lancar"
                            style={{ backgroundColor: 'red' }}
                            onClickEyeIcon={onClickAula}
                        />
                    </div>
                </section>
            </div>
        </div>
    );
}
import { useState } from "react"

const dadosAulaProps = {
    Curso: '',
    Disciplina: '',
    turma_id: '',
    data: '',
    turma_id: '',
    descricao: '',
    atividade_aula: '',
    atividade_casa: '',
}

export default function FormularioAula({ dadosAula = dadosAulaProps,
    onCloseModal = () => { },
    onNextPage = () => { },
    onChangeConteudo = () => { },
    onChangeAtividadeAula = () => { },
    onChangeAtividadeCasa = () => { },
    onChangeAulas = () => { },
    valorAula = 0,
    valorConteudo = '',
    valorAtividadeAula = '',
    valorAtividadeCasa = '',
    minData = new Date().toISOString().slice(0, 10),
}) {

    //comparar 2 data, se a data 1 for menor ou igual que a data 2
    const compareDate = (minData, dataAula) => {
        console.log('minData: ', minData, 'dataAula: ', dataAula)
        const date1 = new Date(minData)
        const date2 = new Date(dataAula)
        const result = date1 <= date2
        return result
    }

    return (
        <div className="modal-container-Editar-Aula">
            <h2>Editar aula</h2>

            <div className="curso-container dados-container">
                <label>Curso</label>
                <input type="text" value={dadosAula.Curso} disabled />
            </div>

            <div className="disciplina-container dados-container">
                <label>Disciplina</label>
                <input type="text" value={dadosAula.Disciplina} disabled />
            </div>

            <div className='turma-data-container'>
                <div className="turma-container dados-container" >
                    <label disabled>Turma</label>
                    <input type="text" value={dadosAula.turma_id} disabled />
                </div>

                <div className="aula-container dados-container">
                    <label>Aulas</label>
                    <input type="number" value={valorAula} onChange={onChangeAulas} />
                </div>

                <div className="data-container dados-container">
                    <label>Data</label>
                    <input type="date" value={dadosAula.data?.slice(0, 10)} disabled />
                </div>

            </div>

            <div className="descricao-container dados-container">
                <label>Descrição</label>
                <textarea type="text" value={valorConteudo} onChange={onChangeConteudo} rows={5} />
            </div>

            <div className="atividade-aula-container dados-container">
                <label>Atividade Aula</label>
                <textarea type="text" value={valorAtividadeAula} onChange={onChangeAtividadeAula} rows={3} />
            </div>

            <div className="atividade-casa-container dados-container">
                <label>Atividade Casa</label>
                <textarea value={valorAtividadeCasa} onChange={onChangeAtividadeCasa} rows={3} />
            </div>

            <div className='buttons-container'>
                <button className='cancel-button' onClick={onCloseModal}>Fechar</button>

                {
                    compareDate(minData, dadosAula.data) &&
                    <button className='confirm-button' onClick={onNextPage}>Continuar</button>
                }
            </div>
        </div>
    )
}
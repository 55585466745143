import { useForm } from 'react-hook-form'
import { CircularProgress, Backdrop } from '@mui/material'
import GerarCheckBoxPorAula from '../../Functions/gerarCheckBoxPorAula'
import './ModalEditarAula.css'

const alunosFaltasProps = {
    id: 0,
    matricula: "",
    data: "",
    codProfessor: "",
    disciplina_id: "",
    faltas: 0,
    nome: "",
    curso: ""
}

export default function FormularioFaltas({
    alunosPermitidos = [alunosFaltasProps],
    valorAulas = 0,
    disciplina_id = "",
    data = "",
    onPreviousPage = () => { },
    onChangeAulas = () => { },
    setFormularioFaltas = () => { },
    onSubmit = () => { },
}) {
    const { register, handleSubmit, errors } = useForm()

    const onConfirm = (form) => {
        setFormularioFaltas(form)
        onSubmit(form)
        
    }

    return (
        <div className="modal-container-Editar-Faltas">
            <h2>Editar faltas</h2>

            

            <div className="faltas-container dados-container">
                <form action="" method="post">
                    {/* <div className="faltas-container dados-container">
                        <label>Número de aulas</label>
                        <input type="number" id="boxAulas" ref={register} name="aulas" value={valorAulas} onChange={onChangeAulas} />
                    </div> */}

                    <div className="alunos-container dados-container">
                        <label>Lista de alunos</label>
                        {/* <hr /> */}
                        {
                            alunosPermitidos.length === 0 ? (
                                <div id="loadingIcon">
                                    <CircularProgress />
                                </div>
                            ) :
                                <table>
                                    <tr>
                                        <th>Matricula</th>
                                        <th>Nome</th>
                                        <th>Faltas</th>
                                        <th>&nbsp; &nbsp;</th>
                                    </tr>

                                    {alunosPermitidos.map((aluno) => (
                                        <tr key={aluno.id} id="alunoField">
                                            <td id="matriculaField" key={aluno.matricula} ref={register}>{aluno.matricula}</td>
                                            <td id="nomeField">{aluno.nome}</td>
                                            <td id="faltasField">
                                                <input
                                                    type="checkbox"
                                                    value={false}
                                                    style={{ display: 'none' }}
                                                    name={aluno.matricula}
                                                    ref={register}
                                                />
                                                {GerarCheckBoxPorAula(valorAulas, aluno, 'matricula', register)}
                                            </td>
                                            <td id="registro">{aluno.faltas}</td>
                                        </tr>
                                    ))}
                                </table>
                        }
                    </div>

                    {/* dados apenas para add no formulario sem display */}
                    <input type="text" name="disciplina" value={disciplina_id} ref={register} style={{ display: 'none' }} />
                    <input type="text" name="data" value={data} ref={register} style={{ display: 'none' }} />

                    <div className='buttons-container'>
                        <button className='cancel-button' onClick={onPreviousPage}>voltar</button>
                        {
                            alunosPermitidos.length > 0 &&
                            <button
                                className='confirm-button'
                                type="submit"
                                name="confirm"
                                onClick={handleSubmit(onConfirm)} >
                                Confirmar
                            </button>
                        }
                    </div>
                </form>
            </div>

        </div >
    );
}
import './FormRelatorio.css'
import React, { useEffect, useState } from 'react'
import GetAlunosOptativas from '../../services/GetAlunosOpByDisciplina'
const { ApiFilter } = require('../../Functions/FilterApiDomain')


export default function FormRelatorioAdmin({
    grades,
    cursos,
    empresa,
    meses,
    turmas,
    alunos,
    register,
    avaliacoes,
    mesPorFaixa = true,
    showAvaliacoes = true,
    showFiltrarEmBranco = true,
    minData = '',
}) {
    const [valorCurso, setValorCurso] = useState('')
    const [valorDisciplina, setValorDisciplina] = useState('')
    const [valorTurma, setValorTurma] = useState('')
    const [valorMes, setValorMes] = useState('')
    const [valorEmpresa, setValorEmpresa] = useState('')
    const [cursoFiltro, setCursoFiltro] = useState([])
    const [turmasFiltro, setTurmasFiltro] = useState([{CodCurso: '0000', Curso: 'TODOS'}])
    const [disciplinaFiltro, setDisciplinaFiltro] = useState([])
    const [alunosFiltro, setAlunosFiltro] = useState([])
    const [checkFiltrarEmBranco, setCheckFiltrarEmBranco] = useState(false)
    const [checkAvaliacao, setCheckAvaliacao] = useState(false)
    const [avaliacoesNotasFiltro, setAvaliacoesNotasFiltro] = useState([])
    const [valorAvaliacao, setValorAvaliacao] = useState([])
    const anoPermitido = localStorage.getItem('Year')
    const [sub, setSub] = useState(localStorage.getItem('target'))
    const baseUrl = ApiFilter(sub)
    let alunosPermitidosGlobalArr

    //Comparar 2 datas, se a data 1 for maior que a data 2, retorna true
    const compareDate = (data1, data2) => {
        const date1 = new Date(data1)
        const date2 = new Date(data2)
        return date1 > date2
    }

    const onChangeCurso = (event) => {
        setValorCurso(event.target.value)
        filtroDisciplina(event.target.value)
        filtroTurmas(event.target.value)

        if (turmasFiltro.length > 0) {
            filtroAlunos(event.target.value, valorTurma)
        }
    }

    const onChangeDisciplina = (event) => {
        setValorDisciplina(event.target.value)
    }

    const onChangeTurma = (event) => {
        setValorTurma(event.target.value)
        filtroAlunos(valorCurso, event.target.value)
    }

    const onChangeEmpresa = (event) => {
        setValorEmpresa(event.target.value)
    }

    const onChangeMes = (event) => {
        setValorMes(event.target.value)
    }

    const filtroCurso = () => {
        // const dadosCursos = cursos
        cursos.push({CodCurso: '0000', Curso: 'TODOS'})
        // dadosCursos.push({ CodCurso: '0000', Curso: 'TODOS' })
        // setCursoFiltro(dadosCursos)
    }

    const filtroDisciplina = (valorCurso) => {
        const dadosDisciplina = []
        const cursosFiltro = grades.filter(item => item.CodCurso === valorCurso)

        cursosFiltro.filter((item) => {
            const itemFind = item
            dadosDisciplina.push({ CodDisciplina: itemFind.CodDisciplina, Disciplina: itemFind.Disciplina })
        })

        // dadosDisciplina.push({CodDisciplina: '888', Disciplina: 'POLIVALENTE'})
        dadosDisciplina.push({ CodDisciplina: '666', Disciplina: 'TODOS' })
        // dadosDisciplina.push({ CodDisciplina: '', Disciplina: 'POLIVALENTE' })

        setDisciplinaFiltro(dadosDisciplina)
    }

    const filtroTurmas = (valorCurso) => {
        const findTurma = turmas.filter(item => item.Curso === valorCurso)
        setTurmasFiltro(findTurma)
    }

    const filtroAlunos = (valorCurso, valorTurma) => {
        const findAlunos = alunos.filter(item => item.Curso === valorCurso && item.Turma === valorTurma)
        const alunosPermitidosNomeOrdem = findAlunos.map((item) => item.Nome).sort()
        const alunosPermitidosPush = []

        for (const valor of alunosPermitidosNomeOrdem) {
            alunosPermitidosPush.push(findAlunos.find((item) => item.Nome === valor))
        }

        alunosPermitidosGlobalArr = alunosPermitidosPush
        setAlunosFiltro(alunosPermitidosPush)
        return alunosPermitidosPush
    }

    const filtroSistemaAvaliacao = (cursos, avaliacoes) => {
        const sistemaAvaliacao = cursos.filter(item => item.CodCurso === valorCurso)[0].Sistema
        const avaliacoesFiltro = avaliacoes?.filter(item => item.Sistema === sistemaAvaliacao)

        const avaliacoesNotas = avaliacoesFiltro?.filter((item) => {
            const find = item.Codigo

            if (find.search('N') !== -1) {
                return item
            }
        })
        setAvaliacoesNotasFiltro(avaliacoesNotas)
    }

    useEffect(() => {
        if (valorCurso !== '') {
            filtroSistemaAvaliacao(cursos, avaliacoes)
        }
    }, [valorCurso])

    useEffect(() => {
        if (cursos?.length > 0) {
            filtroCurso()
        }
    }, [cursos])

    const onChangeCheckEmBranco = (event) => {
        const valor = event.target.value
        const valorBoolean = (String(valor).toLowerCase() === 'true')
        setCheckFiltrarEmBranco(valorBoolean)
    }

    const onChangeCheckAvaliacao = (event) => {
        const valor = event.target.checked
        setCheckAvaliacao(valor)
    }

    const onChangeAvaliacao = (event) => {
        const valor = event.target.value
        setValorAvaliacao(valor)
    }

    useEffect(async () => {
        if (valorDisciplina !== 666) {
            const alunosPermitidos = filtroAlunos(valorCurso, valorTurma)
            const alunosOp = await GetAlunosOptativas(baseUrl, valorDisciplina)

            if (alunosOp?.length > 0 && alunosOp?.length !== undefined) {
                const resultadoFinal = alunosPermitidos?.filter(aluno => alunosOp.filter(alunoArr => alunoArr.Matricula === aluno.Matricula).length)
                setAlunosFiltro(resultadoFinal)
            }
        }
    }, [valorDisciplina])

    return (
        <>
            <form id="formRelatorio" action="" method="post">

                <div className="container-form" id="containerFormRelatorio">
                    <div className='containerFormFirstLine'>
                        <div id="cursoContainerFormRelatorio">
                            <label>Curso</label>
                            <select name="curso" id="curso" value={valorCurso} onChange={onChangeCurso} ref={register} required={true}>
                                <option value={9999} key='CursoBranco'> </option>
                                {
                                    cursos?.map((val, key) => {
                                        return (
                                            <option value={val.CodCurso} key={key}> {val.Curso} </option>
                                        )
                                    })
                                }
                            </select>
                        </div>

                        <div>
                            <label>Turma</label>
                            <select name="turma" onChange={onChangeTurma} value={valorTurma} id="turma" ref={register} required={true}>
                                <option value={4444} key='TurmaBranco' > </option>
                                {
                                    turmasFiltro?.map((val, key) => {
                                        return (
                                            <option value={val.Turma} key={key}> {val.Turma} </option>
                                        )
                                    })
                                }

                            </select>
                        </div>
                    </div>


                    <label>Disciplina</label>
                    <select name="disciplina" onChange={onChangeDisciplina} value={valorDisciplina} id="disciplina" ref={register} required={true} >
                        <option value={6666} key='DisciplinaBranco'></option>
                        {
                            disciplinaFiltro?.map((val, key) => {
                                return (
                                    <option value={val.CodDisciplina} key={key}> {val.Disciplina} </option>
                                )
                            })
                        }

                    </select>

                    {
                        mesPorFaixa === false ?
                            <>
                                <div style={{ display: 'flex', gap: 40 }}>
                                    <div>
                                        <label>Início</label>
                                        <input
                                            type='date'
                                            name={'dataInicial'}
                                            id={'dataInicial'}
                                            ref={register}
                                            min={`${anoPermitido}-01-01`}
                                            max={`${anoPermitido}-12-31`} />
                                    </div>
                                    <div>
                                        <label>Fim</label>
                                        <input
                                            type='date'
                                            name={'dataFinal'}
                                            id={'dataFinal'}
                                            ref={register}
                                            min={`${anoPermitido}-01-01`}
                                            max={`${anoPermitido}-12-31`} />
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <label>Mês</label>
                                <select name="Mes" onChange={onChangeMes} value={valorMes} id="Mes" ref={register} required={true} >
                                    <option value={6666} key='MesBranco'></option>
                                    {
                                        meses?.map((val, key) => {
                                            return (
                                                <option value={val.id} key={key}> {val.Mes} </option>
                                            )
                                        })
                                    }

                                </select>
                            </>
                    }


                    <label>EMPRESA</label>
                    <select name="Empresa" onChange={onChangeEmpresa} value={valorEmpresa} id="Empresa" ref={register} required={true} >
                        <option value={6666} key='EmpresaBranco'></option>
                        {
                            empresa?.map((val, key) => {
                                return (
                                    <option value={JSON.stringify(val)} key={key}> {val.Nome} </option>
                                )
                            })
                        }
                    </select>

                    <select name="alunos" style={{ display: 'none' }} onChange={() => { }} value={alunosFiltro} id="alunos" ref={register} required={true} >
                        <option value={JSON.stringify(alunosFiltro)} key='EmpresaBranco'></option>
                    </select>

                    <div id="CheckOptionsContainer">
                        {
                            showFiltrarEmBranco ?
                                <div id="FiltrarEmBrancoContainer">
                                    <input type="checkbox" name='CheckEmBranco' id="CheckEmBranco" ref={register} onChange={onChangeCheckEmBranco} />
                                    <div>FILTRAR EM BRANCO</div>
                                </div>
                                :
                                <></>}

                        {showAvaliacoes ?
                            <div id="FiltrarCheckAvaliacaoContainer">
                                <input type="checkbox" name='CheckAvaliacao' value={checkAvaliacao} id="CheckAvaliacao" ref={register} onChange={onChangeCheckAvaliacao} />
                                <div>AVALIAÇÃO</div>
                            </div>
                            :
                            <></>}
                    </div>
                    <div id="FiltrarCheckAvaliacaoContainer">
                        <input type="checkbox" name='CheckAssFrequencia' id="CheckAssFrequencia" ref={register} />
                        <div>Assinatura Frequência</div>
                    </div>

                    {checkAvaliacao && showAvaliacoes
                        ?
                        <select name="avaliacao" onChange={onChangeAvaliacao} value={valorAvaliacao} id="avaliacao" ref={register} required={true} >
                            <option value={6666} key='AvaliacaoEmBranco'></option>
                            {
                                avaliacoesNotasFiltro?.map((val, key) => {
                                    return (
                                        <option value={JSON.stringify(val)} key={key}> {val.Abreviatura} </option>
                                    )
                                })
                            }
                        </select>
                        : <></>}

                </div>
            </form>
        </>
    )
}
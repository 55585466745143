import './DiarioPainelPesquisa.css'
export default function DiarioPainelPesquisa(
    {onChangeCurso,
    valorCurso,
    onChangeTurma,
    valorTurma,
    onChangeDisciplina,
    valorDisciplina,
    onChangeDataInicio,
    valorDataInicial,
    onChangeDataFinal,
    valorDataFinal,
    cursos,
    disciplinas,
    filtroTurmas,
    userCursos
}) {
    return (
        <div className='DiarioSearchContainer'>

            <select name="Curso" id="Curso" onChange={onChangeCurso} value={valorCurso} >
                <option value='' id="Curso01">Curso</option>
                {
                    cursos.map((val, key) => {
                        return (
                            userCursos?.includes(val?.Codigo) === true ?
                                <option value={val?.Codigo} key={key} id="Curso01">{val?.Curso}</option>
                                : <></>
                        )
                    })
                }
            </select>

            <select name="Turma" id="Turma" onChange={onChangeTurma} value={valorTurma} >
                <option value='' id="Turma01">Turma</option>
                {
                    filtroTurmas.map((val, key) => {
                        return (
                            <option value={val.Turma} key={key} id="Turma01">{val.Turma}</option>
                        )
                    })
                }
            </select>

            <select name="Disciplina" id="Disciplina" onChange={onChangeDisciplina} value={valorDisciplina} >
                <option value='' >Disciplina</option>
                {
                    disciplinas.map((val, key) => {
                        return (
                            <option value={val.Codigo} key={key} id="Matematica">{val.Disciplina}</option>
                        )
                    })
                }
                <option value="POLIVALENTE" key="POLIVALENTE" id="POLIVALENTE"> POLIVALENTE </option>
            </select>

            <label id='labelInicioDiarioData'>
                Início
            </label>
            <input type='date' onChange={onChangeDataInicio} value={valorDataInicial} />

            <label id='labelFimDiarioData'>
                Fim
            </label>
            <input type='date' onChange={onChangeDataFinal} value={valorDataFinal} />

        </div>
    )
}
import Axios from "axios"

export default async function PostGerarDiario(
    escola,
    idProfessor,
    nomeProfessor,
    nomeCurso,
    nomeDisciplina,
    formulario,
    alunos,
    conteudoFiltroData,
    faltasAlunosPush,
    aulas,
    paginaAtual,
    paginasTotais,
    empresa,
    anoLetivo,
    tipoCurso,
    nomeMes,
    tipoDiario,
    avaliacaoAbreviatura,
    checkAssFrequencia,
    dataAtualPDF,
    baseUrl) {

    const result = await Axios.post(`${baseUrl}/gerarDiario`, {
        escola: escola,
        idProfessor: idProfessor,
        nomeProfessor: nomeProfessor,
        data: dataAtualPDF,
        nomeCurso: nomeCurso,
        nomeDisciplina: nomeDisciplina,
        formulario: formulario,
        alunos: alunos,
        conteudo: conteudoFiltroData,
        aulas: aulas,
        alunosFaltas: faltasAlunosPush,
        paginaAtual: paginaAtual,
        paginasTotais: paginasTotais,
        empresa: empresa,
        anoLetivo: anoLetivo,
        tipoCurso: tipoCurso,
        nomeMes: nomeMes,
        tipoDiario: tipoDiario,
        avaliacaoAbreviatura: avaliacaoAbreviatura,
        checkAssFrequencia: checkAssFrequencia
    })

    return result.data
}
import './Diario.css'
import React, { useState, useEffect } from 'react'

import SideBar from '../../../components/SideBar/SideBar'
import Header from '../../../components/Header/Header'
import DiarioTabela from '../../../components/DiarioTabela/DiarioTabela'
import DiarioPainelPesquisa from '../../../components/DiarioPainelPesquisa/DiarioPainelPesquisa'

import GetCursos from '../../../services/GetCursos'
import GetDisciplina from '../../../services/GetDisciplinas'
import GetTurmas from '../../../services/GetTurmas'
import GetConteudos from '../../../services/GetConteudos'
import SelectAPI from '../../../Functions/SelectApi'

import FiltrarDadosDiario from '../../../Functions/FiltrarDadosDiario'

export default function Diario() {
    const [conteudos, setConteudos] = useState([])
    const [cursos, setCursos] = useState([])
    const [disciplinas, setDisciplinas] = useState([])
    const [turmas, setTurmas] = useState([])
    const [valorCurso, setValorCurso] = useState('')
    const [valorDisciplina, setValorDisciplina] = useState('')
    const [valorTurma, setValorTurma] = useState('')
    const [valorDataInicial, setValorDataInicial] = useState('')
    const [valorDataFinal, setValorDataFinal] = useState('')
    const [filtroTurmas, setFiltroTurmas] = useState([])
    const [filtroConteudos, setFiltroConteudos] = useState([])
    const [valorEscola, setValorEscola] = useState(localStorage.getItem('escola') !== null ? localStorage.getItem('escola') : 'escola')
    const [userCursos, setUserCursos] = useState(sessionStorage.getItem('c'))
    const [searchStatusBarIcon, setSearchStatusBarIcon] = useState(true)
    const [sessionData, setSessionData] = useState(
        sessionStorage.getItem('selectedData') !== undefined ?
            JSON.parse(sessionStorage.getItem('selectedData')) : {
                curso: "",
                disciplina: "",
                turma: "",
                dataInicio: "",
                dataFinal: ""
            })

    const logado = sessionStorage.getItem('isLoged') !== null ? sessionStorage.getItem('isLoged') : 'false'
    let countLimitFirstRenderSession = 0
    let baseUrl = 'http://localhost:3001'
    
    const titlesHeader = [
        "CURSO",
        "TURMA",
        "DISCIPLINA",
        "DATA",
        "AULAS"
    ]
    
    const fieldsBody = [
        "Curso",
        "turma_id",
        "Disciplina",
        "data",
        "aulas"
    ]
    
    let sessionSelectedData = {
        curso: sessionData !== null ? sessionData.curso : '',
        disciplina: sessionData !== null ? sessionData.disciplina : '',
        turma: sessionData !== null ? sessionData.turma : '',
        dataInicio: sessionData !== null ? sessionData.dataInicio : '',
        dataFinal: sessionData !== null ? sessionData.dataFinal : ''
    }

    const updateSessionSelectedData = async () => {
        if (countLimitFirstRenderSession === 0) {
            let session = sessionData
            if (typeof sessionData === 'string') {
                session = sessionData.curso !== undefined ? sessionData : JSON.parse(sessionData);
            }

            if (session?.Codigo !== "" && session !== null) {
                const dadosCurso = await onChangeCurso({ target: { value: session?.curso } });
                const dadosDisciplina = await onChangeDisciplina({ target: { value: session?.disciplina } }, dadosCurso);
                const dadosTurma = await onChangeTurma({ target: { value: session?.turma } }, dadosDisciplina);
                const dadosDataInicio = await onChangeDataInicio({ target: { value: session?.dataInicio } }, dadosTurma);
                await onChangeDataFinal({ target: { value: session?.dataFinal } }, dadosDataInicio);
            }
            countLimitFirstRenderSession++;
        }
    }

    const sessionStorageFilter = (propertyName, propertyValue) => {
        sessionSelectedData[propertyName] = propertyValue
        return sessionSelectedData
    }

    const onChangeCurso = async (event) => {
        console.log('onChangeCurso: ', event)
        let valorCursoAtual = event.target.value
        setValorCurso(valorCursoAtual)

        let filtroTurma = turmas.filter(item => item.Curso == valorCursoAtual)
        setFiltroTurmas(filtroTurma)

        const filtroDados = await FiltrarDadosDiario(
            conteudos,
            valorCursoAtual,
            valorTurma,
            valorDisciplina,
            valorDataInicial,
            valorDataFinal
        )

        setFiltroConteudos(filtroDados)

        const sessionSelectedData = sessionStorageFilter('curso', valorCursoAtual)
        setSessionData(sessionSelectedData)
        return filtroDados
    }

    const onChangeDisciplina = async (event, conteudosPrev = []) => {
        let valorDisciplinaAtual = event.target.value
        setValorDisciplina(valorDisciplinaAtual)

        const filtroDados = await FiltrarDadosDiario(
            conteudosPrev.length > 0 ? conteudosPrev : conteudos,
            valorCurso,
            valorTurma,
            valorDisciplinaAtual,
            valorDataInicial,
            valorDataFinal
        )
        setFiltroConteudos(filtroDados)
        const sessionSelectedData = sessionStorageFilter('disciplina', valorDisciplinaAtual)
        setSessionData(sessionSelectedData)
        return filtroDados
    }

    const onChangeTurma = async (event, conteudosPrev = []) => {
        let valorTurmaAtual = event.target.value
        setValorTurma(valorTurmaAtual)

        const filtroDados = await FiltrarDadosDiario(
            conteudosPrev.length > 0 ? conteudosPrev : conteudos,
            valorCurso,
            valorTurmaAtual,
            valorDisciplina,
            valorDataInicial,
            valorDataFinal
        )
        setFiltroConteudos(filtroDados)

        const sessionSelectedData = sessionStorageFilter('turma', valorTurmaAtual)
        setSessionData(sessionSelectedData)
        return filtroDados
    }

    const onChangeDataInicio = async (event) => {
        let valorDataInicioAtual = event.target.value
        setValorDataInicial(valorDataInicioAtual)

        const filtroDados = await FiltrarDadosDiario(
            conteudos,
            valorCurso,
            valorTurma,
            valorDisciplina,
            valorDataInicioAtual,
            valorDataFinal
        )
        setFiltroConteudos(filtroDados)
        const sessionSelectedData = sessionStorageFilter('dataInicio', valorDataInicioAtual)
        setSessionData(sessionSelectedData)

        return filtroDados
    }

    const onChangeDataFinal = async (event) => {
        let valorDataFinalAtual = event.target.value
        setValorDataFinal(valorDataFinalAtual)

        const filtroDados = await FiltrarDadosDiario(
            conteudos,
            valorCurso,
            valorTurma,
            valorDisciplina,
            valorDataInicial,
            valorDataFinalAtual
        )
        setFiltroConteudos(filtroDados)

        const sessionSelectedData = sessionStorageFilter('dataFinal', valorDataFinalAtual)
        setSessionData(sessionSelectedData)

        return filtroDados
    }

    function FiltrarDisciplinas(conteudos, disciplinas) {
        let novoConteudo = []

        conteudos.filter((curso, index) => {
            const resultado = disciplinas.filter(disciplina => disciplina.Codigo === curso?.disciplina_id)[0]
            novoConteudo.push(curso)

            if (resultado?.Disciplina !== undefined) {
                novoConteudo[index]['Disciplina'] = resultado?.Disciplina
            } else {
                novoConteudo[index]['Disciplina'] = 'Polivalente'
            }
        })

        return novoConteudo
    }

    async function filtrarConteudos(conteudoConsulta, disciplinas) {
        conteudoConsulta = conteudoConsulta?.filter(item => userCursos?.includes(item.curso_id))
        const conteudoComDisciplinas = FiltrarDisciplinas(conteudoConsulta, disciplinas)
        setConteudos(conteudoComDisciplinas)
        setFiltroConteudos(conteudoComDisciplinas)
        setSearchStatusBarIcon(value => value = false)
    }

    function cursosUserPermissao() {
        const permitidos = userCursos?.split(';')?.filter(item => item !== '')
        setUserCursos(permitidos)
    }

    useEffect(() => {
        baseUrl = SelectAPI(valorEscola)
    }, [valorEscola])

    useEffect(() => {
        Promise.all([
            GetCursos(baseUrl),
            GetDisciplina(baseUrl),
            GetTurmas(baseUrl),
            GetConteudos(baseUrl)
        ]).then((result) => {
            setCursos(result[0])
            setDisciplinas(result[1])
            setTurmas(result[2])
            setConteudos(result[3])
            cursosUserPermissao()
            filtrarConteudos(result[3], result[1])
        })
    }, [])

    useEffect(() => {
        updateSessionSelectedData()
    }, [conteudos])

    if (logado !== 'true') {
        window.location.replace('/Admin/Login')
        sessionStorage.clear()
        localStorage.clear()
    } else {
        return (
            <>
                <div id='DiarioGeneralAdminContainer'>
                    <SideBar />
                    <div className='DiarioAdminContainer'>
                        <Header />

                        <div id="DiarioTitlePage">
                            DIARIO
                        </div>

                        <DiarioPainelPesquisa
                            cursos={cursos}
                            disciplinas={disciplinas}
                            filtroTurmas={filtroTurmas}
                            onChangeCurso={onChangeCurso}
                            onChangeTurma={onChangeTurma}
                            onChangeDisciplina={onChangeDisciplina}
                            onChangeDataInicio={onChangeDataInicio}
                            onChangeDataFinal={onChangeDataFinal}
                            valorCurso={valorCurso}
                            valorTurma={valorTurma}
                            valorDisciplina={valorDisciplina}
                            valorDataInicial={valorDataInicial}
                            valorDataFinal={valorDataFinal}
                            userCursos={userCursos}
                            key={'DiarioPainelPesquisa'}
                         />

                        <DiarioTabela
                            searchBarShow={false}
                            headerTableTitles={titlesHeader}
                            bodyTableFields={fieldsBody}
                            bodyDataRows={filtroConteudos}
                            searchStatusIcon={searchStatusBarIcon}
                            sessionValues={sessionData}
                        />
                    </div>
                </div>
            </>
        )
    }
}
import FiltrarAlunosOp from "../Functions/FiltrarAlunosOp"
import GetAlunosOptativas from "./GetAlunosOpByDisciplina"

export default async function ProcessarTodasDisciplinasDiario(
    disciplinasTotais,
    alunos,
    curso,
    turma,
    dataMes,
    empresa,
    anoPermitido,
    tipoCursoFiltro,
    lengthDisciplinas,
    checkAssFrequencia,
    checkEmBrancoAtual,
    tipoDiario,
    baseUrl,
    paginaAtualGlobal,
    handleGetFaltas
) {
    
    for (const disciplinaItem of disciplinasTotais) {
        const propDisciplina = checkEmBrancoAtual ? 'disciplina_id' : 'CodDisciplina'
        //CODIGO DISCIPLINA DISCIPLINA
        const disciplina = disciplinaItem[propDisciplina] === '999' ? '' : disciplinaItem[propDisciplina]
        // const nomeDisciplina = disciplinaItem[propDisciplina] === '999' ? 'POLIVALENTE' : disciplinaItem.Disciplina
        const alunosOp = await GetAlunosOptativas(baseUrl, disciplina)
        console.log('alunosOp', alunosOp)
        const alunosOpFiltro = FiltrarAlunosOp(alunosOp, alunos)
        console.log('alunosOpFiltro', alunosOpFiltro)

        //INDEX DISCIPLINA
        const indexDisciplina = disciplinasTotais.findIndex(item => item[propDisciplina] === disciplinaItem[propDisciplina])
        paginaAtualGlobal = indexDisciplina

        // alunos, 
        // disciplina, 
        // curso, 
        // turma, 
        // dataInicial, 
        // dataFinal, 
        // paginaAtual, 
        // paginasTotais, 
        // empresa, 
        // anoLetivo, 
        // tipoCurso, 
        // nomeMes, 
        // tipoDiario, 
        // checkAssFrequencia

        await handleGetFaltas(
            alunosOpFiltro,
            disciplina,
            curso,
            turma,
            dataMes.dataInicial,
            dataMes.dataFinal,
            indexDisciplina,
            lengthDisciplinas,
            empresa,
            anoPermitido,
            tipoCursoFiltro,
            dataMes.nomeMes,
            tipoDiario,
            checkAssFrequencia
        )
    }

    return true
}
export default function FiltrarTipoCurso(nomeCurso, tipoCursoProp) {
    let tipoCurso = tipoCursoProp
    if (nomeCurso.includes('FUNDAMENTAL')) {
        tipoCurso = 'FUNDAMENTAL'

    } else if (nomeCurso.includes('MÉDIO') || nomeCurso.includes('MEDIO')) {
        tipoCurso = 'MEDIO'

    } else {
        tipoCurso = 'INFANTIL'
    }

    return tipoCurso
}
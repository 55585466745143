import Axios from "axios"

export default async function GetFaltasGerarDiario(baseUrl, alunos, dataInicial, dataFinal, disciplina) {
    const faltas = await Axios.post(`${baseUrl}/getFaltasGerarDiario`, {
        alunos: alunos,
        dataInicial: dataInicial,
        dataFinal: dataFinal,
        disciplina: disciplina
    })

    console.log('faltas', faltas.data)
    return faltas.data
}